import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { BASE_URL } from "../../../domain/Types/Config";
import { Review } from "../../../domain/Types/Review";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const SaveMenuReview = async (review: Review): Promise<Review> => {
  try {
        // Check for internet connectivity
        const online = await isOnline();
        if (!online) {
        console.log("No internet connection. Retrying...");
        return SaveMenuReview(review); // Retry fetching data
        }

        const url = `${BASE_URL}/reviews`;
        const response: AxiosResponse<Review> = await axios.post(url,review);

        return response.data;
    } catch (error) 
    {
        console.error('Error fetching data:', error);
        throw error;
    }
};