import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Box, CardActions } from '@mui/material';
import { Menu } from '../../../../domain/Types/Menu';
import { Review } from '../../../../domain/Types/Review';

interface Props {
    FoodMenu: Menu;
    onSelectmenu: (FoodMenu: Menu) => void;
    onViewOption: (FoodMenu: Menu) => void;
}

const MenuCard = ({FoodMenu, onSelectmenu, onViewOption}: Props) => {
  const navigate = useNavigate();

  // Navigate to the reviews page for this menu item
  const handleViewReviews = () => {
    navigate("/Reviews", { state: { menuItem: FoodMenu } });
  };

  return (
    <div>
        <Card className="m-3" sx={{ maxWidth: 450, minWidth: 220, borderRadius: '16px', position: 'relative' }} >
            <CardMedia
              sx={{ height: 140, borderRadius: '16px', position: 'relative' }}
              image={`https://app.foodhut.mw/Admin/public/images/${FoodMenu.image_path}`}
              title={FoodMenu.name}
            >
              {/* Add to Cart Button */}
              <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                m: 1
              }}>
                <IconButton
                  color="primary"
                  onClick={() => onSelectmenu(FoodMenu)}
                >
                  <AddShoppingCartIcon />
                </IconButton>
              </Box>
            </CardMedia>
            <CardContent>
                <Typography variant="h6">
                    {FoodMenu.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {FoodMenu.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    <span className="font-bold">MK</span> {(Number(FoodMenu.price) || 0).toFixed(2)}
                </Typography>
                
                {/* Display rating */}
                <Rating
                    name={`rating-${FoodMenu.id}`}
                    value={FoodMenu.average_rating || 0} // Display average rating
                    readOnly
                    precision={0.1}
                />
            </CardContent>
            <CardActions>
                <Button size="small" variant="outlined" onClick={() => {onViewOption(FoodMenu)}}>Options</Button>
                <Button size="small" variant="outlined" onClick={() => handleViewReviews()}>Reviews</Button>
            </CardActions>
        </Card> 
    </div>
  );
};

export default MenuCard;