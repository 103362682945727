import { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomerLayout from '../customer/Components/Layouts/Customer/CustomerLayout';
import { useForm, Controller, SubmitHandler, FieldValues} from "react-hook-form";
import {TextField } from "@mui/material";

const PaymentPreferences = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
        } = useForm();

        //const navigate = useNavigate();
        //const win = window.sessionStorage;
    
    const [loginerrors, setErrors] = useState([]);
    const [message, setMessage] = useState("");        

    // Watch the password and confirmPassword fields for changes
    //const password = watch('password', '');
    //const confirmPassword = watch('confirmPassword', '');
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  // Add a custom validation rule to check if passwords match
  //const passwordMatch = (value: string) => value === password || 'Passwords do not match';        


    const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => 
        {
            data.toString();
            setMessage("");
            setErrors([]);
        }

  return (
    <CustomerLayout>
                            <div className="page-section mb-60">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6">
                                    {/*<!-- Login Form s-->*/}
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="login-react-Form">
                                            <h4 className="login-title">Login</h4>
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                <span className="text-red-500">{loginerrors}{message}</span>
                                                    <Controller
                                                                    name="email"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{
                                                                        required: 'Email address is required',
                                                                        pattern: {
                                                                        value: emailRegex,
                                                                        message: 'Invalid email address',
                                                                        },
                                                                    }}
                                                                    render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id="email"
                                                                        label="Email Address"                        
                                                                        variant="outlined"
                                                                        placeholder="Email Address"
                                                                        fullWidth    
                                                                        margin="normal"                                             
                                                                        error={!!errors.email}
                                                                        helperText={errors.email && "Email is required"}
    
                                                                    />
                                                                    )}
                                                    />                                            
                                                </div>
                                                <div className="col-12">
                                                    <Controller
                                                                    name="password"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id="password"
                                                                        type="password"                                                                    
                                                                        label="Password"                        
                                                                        variant="outlined"
                                                                        placeholder="Enter Password"
                                                                        fullWidth      
                                                                        margin="normal"                                                                                                           
                                                                        error={!!errors.password}
                                                                        helperText={errors.password && "Password is required"}
                                                                    />
                                                                    )}
                                                    />                                             
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                                                        <Controller
                                                            name="remember_me"
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({ field }) => (
                                                                <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                                                                <input
                                                                    {...field}
                                                                    type="checkbox"
                                                                    id="remember_me"
                                                                    onChange={(e) => setValue("remember_me", e.target.checked)}
                                                                />
                                                                <label htmlFor="remember_me">Remember me</label>
                                                                </div>
                                                            )}
                                                        />                                                    
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-10 mb-20 text-left text-md-right">
                                                    <Link to={{pathname: `/ForgotPassword` }}> Forgotten password?</Link>
                                                    <Link to={{pathname: `/Register` }}> Register</Link>
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="register-button mt-0">Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
    </CustomerLayout>
  )
}

export default PaymentPreferences