import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { Category } from "../../../domain/Types/Category";
import { BASE_URL } from "../../../domain/Types/Config";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const fetchCategoriesData = async (): Promise<Category[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchCategoriesData(); // Retry fetching data
    }

    //const url = "https://app.foodhut.mw/api/Businesscategory";
    const url = `${BASE_URL}/Businesscategory`;
    const response: AxiosResponse<Category[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};