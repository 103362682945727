import { createContext, ReactNode, useContext, useReducer  } from "react"
import { CartReducer } from "./Reducers"

const Cart = createContext<{ state: any; dispatch: React.Dispatch<any>; }>({ state: null, dispatch: () => {} })

type CartProviderProps = {
    children: ReactNode
}

const Context = ({ children }: CartProviderProps) => {

const products = [...Array(20)].map((_, index)=>({
    id: index,
    name: "Mushroom Pizza",
    description: "Pizza with Mushroom and Cheese"
}))

const [state, dispatch] = useReducer(CartReducer, {products: products, Cart: []})

  return <Cart.Provider value = {{state, dispatch}}>{children}</Cart.Provider>
};

export default Context

export const CartState = () => {
    return useContext(Cart)
}