import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DeliveryService from '../../../../../assets/DeliveryService.png';
import ordersuccess from '../../../../../assets/ordersuccess.png';
import OnlineShopping from '../../../../../assets/OnlineShopping.png';
import FoodFootersupport from '../../../../../assets/FoodFootersupport.png';
import AndroidDownload from '../../../../../assets/AndroidDownload.png';

const Footer = () => {

    const navigate = useNavigate();
    const handleCategoryClick = (categoryName: string) => {
        navigate('/FoodCategory', { state: { categoryName } });
      };

  return (
    <>
                    <div style={{ borderTop: '1px solid #ccc' }}></div>
                    {/*<!-- Begin Footer Area -->*/}
                    <div className="footer mt-15">
                        {/*<!-- Begin Footer Static Top Area -->*/}
                        <div className="footer-static-top">
                            <div className="container">
                                {/*<!-- Begin Footer Shipping Area -->*/}
                                <div className="footer-shipping pb-55 pb-xs-25">
                                    <div className="row">
                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-sm-55 pb-xs-55">
                                            <div className="li-shipping-inner-box">
                                                <div className="shipping-icon">
                                                <img src={DeliveryService} alt="Delivery" style={{ width: '70%', height: 'auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>Fast Reliable Delivery</h2>
                                                    <p>And free returns. See checkout for delivery dates.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}
                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-sm-55 pb-xs-55">
                                            <div className="li-shipping-inner-box">
                                                <div className="shipping-icon">
                                                <img src={ordersuccess} alt="Delivery" style={{ width: '70%', height: 'auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>Safe Payment</h2>
                                                    <p>Pay with the world's most popular and secure payment methods.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}
                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-xs-30">
                                            <div className="li-shipping-inner-box">
                                                <div className="shipping-icon">
                                                <img src={OnlineShopping} alt="Delivery" style={{ width: '70%', height: 'auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>Shop with Confidence</h2>
                                                    <p>Our Buyer Protection covers your purchase from click to delivery.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}
                                        {/*<!-- Begin Li's Shipping Inner Box Area -->*/}
                                        <div className="col-lg-3 col-md-6 col-sm-6 pb-xs-30">
                                            <div className="li-shipping-inner-box">
                                                <div className="shipping-icon">
                                                <img src={FoodFootersupport} alt="Delivery" style={{ width: '70%', height: 'auto' }} />
                                                </div>
                                                <div className="shipping-text">
                                                    <h2>24/7 Help Center</h2>
                                                    <p>Have a question? Call a Specialist or chat online.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Li's Shipping Inner Box Area End Here -->*/}
                                    </div>
                                </div>
                                {/*<!-- Footer Shipping Area End Here -->*/}
                            </div>
                        </div>
                        {/*<!-- Footer Static Top Area End Here -->*/}
                        {/*<!-- Begin Footer Static Middle Area -->*/}
                        <div className="footer-static-middle">
                            <div className="container">
                                <div className="footer-logo-wrap pt-50 pb-35">
                                    <div className="row">
                                        {/*<!-- Begin Footer Block Area -->*/}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div className="footer-block">
                                                <h3 className="footer-block-title">Popular Categories</h3>
                                                <ul>
                                                    <li>                                                
                                                    <a 
                                                        href="#" 
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevent default action
                                                            handleCategoryClick("Restaurants"); // Handle click and navigation
                                                        }} 
                                                        className="no-underline"
                                                    >
                                                        Restaurants
                                                    </a>
                                                    </li>
                                                    <li><a href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevent default action
                                                            handleCategoryClick("Supermarkets"); // Handle click and navigation
                                                        }} 
                                                        className="no-underline"
                                                    >Supermarkets</a></li>
                                                    <li><a href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevent default action
                                                            handleCategoryClick("FoodTruck"); // Handle click and navigation
                                                        }}        
                                                        className="no-underline"                                            
                                                    >Food Trucks</a></li>
                                                    <li><a href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault(); // Prevent default action
                                                            handleCategoryClick("Bakeries"); // Handle click and navigation
                                                        }}        
                                                        className="no-underline"                                            
                                                    >Bakeries</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<!-- Footer Block Area End Here -->*/}
                                        {/*<!-- Begin Footer Block Area -->*/}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div className="footer-block">
                                                <h3 className="footer-block-title">Our Services</h3>
                                                <ul>
                                                    <li><a href="#">Foodhut - Food Ordering</a></li>
                                                    <li><a href="#">Delivery Service</a></li>
                                                    <li><a href="#">Marketing</a></li>
                                                    <li><a href="#">Advertising</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<!-- Footer Block Area End Here -->*/}
                                        {/*<!-- Begin Footer Block Area -->*/}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div className="footer-block">
                                                <h3 className="footer-block-title">Resources</h3>
                                                <ul>
                                                    <li><a href="#">About Us</a></li>
                                                    <li><a href="#">Affilliates</a></li>
                                                    <li><a href="#">Contact Us</a></li>
                                                    <li><a href="#">Plan and Pricing</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<!-- Footer Block Area End Here -->*/}                                                                                
                                        {/*<!-- Begin Footer Block Area -->*/}
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <div className="footer-block">
                                                <h3 className="footer-block-title">Our company</h3>
                                                <ul>
                                                    <li><a href="#">Terms and Condition</a></li>
                                                    <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>                                                    
                                                    <li><a href="#">Support Policy</a></li>
                                                    <li><a href="#">Refund Policy</a></li>
                                                    <li><a href="#">FAQs</a></li>
                                                    <li><a href="#">Download</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<!-- Footer Block Area End Here -->*/}
                                        {/*<!-- Begin Footer Block Area -->*/}
                                        <div className="col-lg-4">
                                            <div className="footer-block">
                                                <h3 className="footer-block-title">Download Android</h3>
                                                <div>
                                                <img src={AndroidDownload} alt="Delivery" style={{ width: '70%', height: 'auto' }} />
                                                </div>
                                                <ul className="social-link">
                                                    <li className="twitter">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Twitter">
                                                            <i className="fa fa-twitter"></i>
                                                        </a>

                                                    </li>
                                                    <li className="rss">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="RSS">
                                                            <i className="fa fa-rss"></i>
                                                        </a>
                                                        
                                                    </li>
                                                    <li className="Instagram">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Instagram">
                                                            <i className="fa fa-google-plus"></i>
                                                        </a>
                                                    </li>
                                                    <li className="facebook">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Facebook">
                                                            <i className="fa fa-facebook"></i>
                                                        </a>
                                                    </li>
                                                    <li className="youtube">
                                                        <a href="#" data-toggle="tooltip" target="_blank" title="Youtube">
                                                            <i className="fa fa-youtube"></i>
                                                        </a>
                                                    </li>
                                                    <li className="instagram">
                                                        <a href="https://www.instagram.com/" data-toggle="tooltip" target="_blank" title="Instagram">
                                                            <i className="fa fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/*<!-- Footer Block Area End Here -->*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<!-- Footer Static Middle Area End Here -->*/}
                    </div>
                    {/*<!-- Footer Area End Here -->*/} 
                    <footer style={{ padding: '20px', textAlign: 'center', backgroundColor: '#f1f1f1' }}>
                        <Typography variant="body1">© 2024 FoodHut. All Rights Reserved.</Typography>
                    </footer>
    </>
  )
}

export default Footer