import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Business } from "../../domain/Types/Business";
import { fetchCategoriesData } from '../../infrastructure/api/Category/CategoryAPI';
import { fetchBusinessbyCategory } from '../../infrastructure/api/Business/BusinessAPI';
import { Category } from "../../domain/Types/Category";
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import FoodhutChip from "./Components/OtherComponents/FoodhutChip";
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, Grid, CircularProgress, Typography } from '@mui/material';
import BusinessCard from './Components/OtherComponents/BusinessCard';
import ShoppingCartFloat from './Components/OtherComponents/ShoppingCartFloat';
import { Search } from '../../domain/Types/Search';

const FoodStoreCategory = () => {
    const { Category: initialFoodstorecat } = useParams<{ Category: string }>();
    const [business, setBusiness] = useState<Business[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<Category[] | null>(null);
    const [showAllChips, setShowAllChips] = useState(false);
    const [foodstorecat, setFoodstorecat] = useState<string | undefined>(initialFoodstorecat);
    const navigate = useNavigate();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    //const isMd = useMediaQuery(theme.breakpoints.down('md'));


    useEffect(() => {
        const searchCriteria: Search = {
            search: 'Food',
            BusinessCategory: foodstorecat || 'Restaurant',
            location: 'Blantyre',
            foodtype: 'Burger',
            minimumPrice: 1000,
            maximumprice: 4000
        };

        const fetchData = async () => {
            try {
                const data = await fetchBusinessbyCategory(searchCriteria);
                console.log(data);
                setBusiness(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        if (!category) {
            const fetchCategories = async () => {
                try {
                    const data = await fetchCategoriesData();
                    setCategory(data);
                } catch (error) {
                    console.error('Error fetching categories:', error);
                }
            };

            fetchCategories();
        }
    }, [foodstorecat]);

    const handleFoodStoreselect = (foodstore: Business) => {
        navigate(`/FoodStores/${foodstore.id}`);
    };

    const handleFoodhutCategory = (category: Category) => {
        setLoading(true);
        setFoodstorecat(category.name);

        const searchCriteria: Search = {
            search: 'Food',
            BusinessCategory: category.name,
            location: 'Blantyre',
            foodtype: 'Burger',
            minimumPrice: 1000,
            maximumprice: 4000
        };

        const fetchData = async () => {
            try {
                const data = await fetchBusinessbyCategory(searchCriteria);
                console.log(data);
                setBusiness(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    };

    const displayedCategories = showAllChips ? category : category?.slice(0, 18);

    return (
        <CustomerLayout>
            <Stack
                direction="row"
                spacing={isXs ? 0.25 : isSm ? 0.5 : 1}
                className={`my-3 flex-wrap gap-2 ${isXs || isSm ? 'justify-center' : 'justify-start'}`}
            >
                {displayedCategories ? displayedCategories.map((cat: Category) => (
                    <FoodhutChip Category={cat.name} key={cat.id} onSelectCategory={() => handleFoodhutCategory(cat)} />
                )) : null}
                {category && category.length > 5 && (
                    <Button onClick={() => setShowAllChips(!showAllChips)}>
                        {showAllChips ? 'Show Less' : 'Show More'}
                    </Button>
                )}
            </Stack>
            <div className="font-black mb-2">
                {foodstorecat}
            </div>
            <Grid container spacing={2}>
                <Grid item md={9} container direction="row" spacing={2}>
                    {loading ? (
                      <div className="mt-5 ml-2">
                          <CircularProgress />
                      </div>
                        
                    ) : (
                        business && business.length > 0 ? business.map((biz: Business) => (
                            <Grid item md={3} key={biz.id}>
                                <BusinessCard foodstore={biz} onSelectfoodstore={handleFoodStoreselect} />
                            </Grid>
                        )) : (
                            <Typography variant="h6">{foodstorecat} Coming Soon</Typography>
                        )
                    )}
                </Grid>
                <Grid item md={3}>
                    <ShoppingCartFloat />
                </Grid>
            </Grid>
        </CustomerLayout>
    );
};

export default FoodStoreCategory;