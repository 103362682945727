import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Payment } from '../../domain/Types/Payment';
import { MakeRequest } from '../../infrastructure/api/Order/OrderAPI';
import { OrderRequest } from '../../domain/Types/OrderRequest';
import { ShoppingCart } from '../../domain/Types/ShoppingCart';
import { DeliveryDetails } from '../../domain/Types/DeliveryDetails';
import PaymentConfirmForm from './Components/Forms/PaymentConfirmForm';
import SpinnerModal from '../app/SpinnerModal';
import { ShoppingCartContext } from "../../utilities/Context/ShoppingCartContext";
import { database, ref, set } from '../../utilities/firebaseConfig';

const PaymentConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const win = window.sessionStorage;
  const [loading, setLoading] = useState(false);
  const [connect, setConnect] = useState("");

  const { shoppingcart, selectedBusinessId, dispatch } = useContext(ShoppingCartContext);  // Get selectedBusinessId and dispatch from the ShoppingCartContext
  const { reset } = useForm();

  interface FormData {
    PaymentMethod: string;
    PaymentRef: string;
    VisaCardNumber?: string;
    VisaExpiryDate?: string;
    AirtelMoneyNumber?: string;
    MpambaNumber?: string;
    deliveryOption: 'pick-up' | 'delivery';
    DeliveryAddress?: string;
    FirstName: string;
    LastName: string;
    email: string;
    cellnumber: string;
    TotalPrice: number;
    VAT: number;
    TotalPriceIncludingVAT: number;
    DeliveryFee: number;
    cartitems: ShoppingCart[];
  }

  const MakeanOrderRequest = async (ord: OrderRequest) => {
    try {
      setConnect("Please Wait, While we Send Your Order...");
      setLoading(true);

      const data = await MakeRequest(ord);
      const { order_number } = data;

        // Create a chat entry in the Firebase Realtime Database
        const chatRef = ref(database, order_number);
        await set(chatRef, {
            order_number: order_number,
            messages: [],
            createdAt: new Date().toISOString(),
        });      

      Swal.fire({
        title: 'SUCCESS',
        text: `Your Order has Placed Successfully: Order #${order_number}`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      });

      // Empty the cart after successful order placement
      dispatch({ type: 'EMPTY_CART' });

      navigate("/TrackOrder");

    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
      Swal.fire({
        title: 'Unable to Send',
        text: 'Your Order Could not be sent Please try again',
        icon: 'warning',
        showConfirmButton: false,
        timer: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    const userIdString = win.getItem("userid");
    const userId = userIdString ? parseInt(userIdString) : 0;

    const delivery: DeliveryDetails = {
      id: 1,
      user_id: userId,
      delivery_address: "",
      latitude: 111111,
      longitude: 11111
    };

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Formats date as 'YYYY-MM-DD'

    const Pay: Payment = {
      id: 0,
      paymentoption_id: 1,
      amount: data.TotalPriceIncludingVAT,
      description: "PAYMENT FOR FOOD ORDER",
      payment_date: formattedDate,
      status: "",
      transaction_id: data.PaymentRef,
    };

    // Filter cart items to only include those for the selected businessId
    const filteredCartItems = data.cartitems.filter(item => item.businessId === selectedBusinessId);

    const cart_items = filteredCartItems.map(item => ({
      id: item.id,
      imagepath: item.imagepath,
      menu_id: item.menu_id,
      name: item.name,
      order_id: item.order_id,
      price: item.price,
      quantity: item.quantity,
      session_id: item.session_id,
      status: item.status,
      total: item.total,
      user_id: item.user_id,
      productattributevalues: [],
      businessId: item.businessId,
      businessname: item.businessname
    }));

    const NewOrder: OrderRequest = {
      customerid: userId,
      businessid: selectedBusinessId, // Use selectedBusinessId here
      cartitems: cart_items,
      totalamount: data.TotalPriceIncludingVAT,
      paymentid: 1,
      orderstatusid: 1,
      deliveryid: 1,
      deliveryDetails: delivery,
      PaymentDetails: Pay,
    };

    MakeanOrderRequest(NewOrder);

    reset();
  };

  return (
    <>
      <CustomerLayout>
        <PaymentConfirmForm onSubmit={onSubmit} />
      </ CustomerLayout>
      <SpinnerModal isLoading={loading} message={connect} />
    </>
  )
}

export default PaymentConfirmation;