import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Divider from '@mui/material/Divider';
import Withauth from "../../../../utilities/WithAuthentication/Withauth";

const DriverLayout = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <Navigation />
        </div>
        {props.children}
        <Divider orientation="horizontal" flexItem />
        <div className="row">
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Withauth(DriverLayout)