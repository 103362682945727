import React from 'react';
import { useState } from 'react';
import SingleProduct from '../customer/Components/OtherComponents/SingleProduct';
import AppLayout from '../customer/Components/Layouts/App/AppLayout';
import { Button, TextField, Box, Typography, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

function ProductPage() {

  // Explicitly specify the type of items as an array of strings
  const [items, setItems] = useState<string[]>([]);
  const [newItem, setNewItem] = useState<string>('');

  // Function to handle adding a new item
  const handleAddItem = () => {
    if (newItem.trim()) {
      setItems([...items, newItem]);
      setNewItem('');
    }
  };

  // Function to handle removing an item by index
  const handleRemoveItem = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };


  return (
    <AppLayout>
    <Box sx={{ padding: 3 }}>
    <Typography variant="h4" gutterBottom>
      Menu Options
    </Typography>
    <Box sx={{ marginBottom: 2 }}>
      <TextField
        label="Add new topping"
        variant="outlined"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        sx={{ marginRight: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleAddItem}>
        Add
      </Button>
    </Box>
    <Box>
      {items.length > 0 ? (
        <Box>
          {items.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
                padding: 1,
                border: '1px solid #ddd',
                borderRadius: 1
              }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {item}
              </Typography>
              <IconButton
                aria-label="remove item"
                color="error"
                onClick={() => handleRemoveItem(index)}
              >
                <Remove />
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No items added yet.
        </Typography>
      )}
    </Box>
  </Box>
  </AppLayout>
  )
}

export default ProductPage