import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomerLayout from "./Components/Layouts/Customer/CustomerLayout";
import { ShoppingCartContext } from "../../utilities/Context/ShoppingCartContext";
import FoodHutBtn from './Components/CommmonComponents/FoodHutBtn';
import { Button } from '@mui/material';

const CheckOut = () => {
    const { shoppingcart, selectedBusinessId } = useContext(ShoppingCartContext);

    // Filter shopping cart items by the selected businessId
    const filteredItems = shoppingcart.filter(item => item.businessId === selectedBusinessId);

    // Calculate total price for the selected business items
    const totalPrice = filteredItems.reduce((total, item) => total + item.quantity * item.price, 0);

    return (
        <CustomerLayout>
            <h2>Checkout for Business ID: {selectedBusinessId}</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Total Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredItems.map(cart => (
                            <TableRow
                                key={cart.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{cart.name}</TableCell>
                                <TableCell align="right">{cart.quantity}</TableCell>
                                <TableCell align="right">{cart.price}</TableCell>
                                <TableCell align="right">{cart.quantity * cart.price}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                <p><strong>Total: MK {totalPrice.toFixed(2)}</strong></p>
                <Button
                    variant="contained"
                    size="small"
                    component={Link} 
                    to={`/PaymentConfirmation`}
                >
                    Confirm Payment
                </Button>
            </div>
        </CustomerLayout>
    );
};

export default CheckOut;