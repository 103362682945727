import {useNavigate} from 'react-router-dom';
import {useContext} from 'react'
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
//import { CartState } from '../../../../utilities/Context/Context';
import { ShoppingCartContext } from '../../../../utilities/Context/ShoppingCartContext';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const ShoppingBadge = () => {

const navigate = useNavigate();
const { shoppingcart, getTotalItems } = useContext(ShoppingCartContext);

const handleNavigate = () =>{
    navigate("/ShoppingCart")
}

//const {state: {products},} = CartState();

  return (
    <IconButton aria-label="cart" onClick={handleNavigate}>
      <StyledBadge badgeContent={getTotalItems(shoppingcart)} color="primary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  )
}

export default ShoppingBadge