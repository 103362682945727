import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { User } from "../../../domain/Types/User";
import { Order } from "../../../domain/Types/Order";
import { OrderRequest } from "../../../domain/Types/OrderRequest";
import { OrderSearch } from "../../../domain/Types/OrderSearch";
import { DetailedOrder } from "../../../domain/Types/DetailedOrder";
import { BASE_URL } from "../../../domain/Types/Config";
//import { FcmNotification } from "../../../domain/Types/FcmNotification";
//import { SendNotification } from "../FcmToken/TokenFcm";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const MakeRequest = async (order: OrderRequest): Promise<Order> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return MakeRequest(order); // Retry fetching data
    }

    const url = `${BASE_URL}/MobilePrepareOrder`;
    const response: AxiosResponse<Order> = await axios.post(url, order);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchUserOrderData = async (user: User): Promise<Order[]> => {
    try {
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return fetchUserOrderData(user);
      }
  
      const url = `${BASE_URL}/ordersForUser/${user.id}`;
      const response: AxiosResponse<Order[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const SearchOrders = async (search: OrderSearch): Promise<DetailedOrder[]> => {
    try {
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return SearchOrders(search);
      }
  
      const url = `${BASE_URL}/SearchOrders`;
      const response: AxiosResponse<DetailedOrder[]> = await axios.post(url,search);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  
  export const UpdateOrderStatus = async (orderId: number, newStatus: string): Promise<DetailedOrder> => {
      try {
          const online = await isOnline();
          if (!online) {
              console.log("No internet connection. Retrying...");
              return UpdateOrderStatus(orderId, newStatus);
          }
  
          const url = `${BASE_URL}/UpdateOrderStatus`;
          const response: AxiosResponse<{ order: DetailedOrder }> = await axios.post(url, {
              id: orderId,
              orderstatus_id: newStatus,
          });


          /*const notification: FcmNotification = {
            to: "fR98rpAQwNjKKLlH6oUQKE:APA91bH-CIDBVT8eqh_KAc_esVhB36gf4JqTmHhhRDlddUdvsUi71IwVElewVbPGeMULlEj9cso0ocB7vI3ZTKaeD0j6eH5VBgv-2R05KFMbDnPFhaP_nSlLRYy4L3yMGFN1OnWXFqF_",    // Set the recipient's FCM token here
            title: "your Order has Been Updated", // Set the notification title here
            body: newStatus   // Set the notification body here
          };
          
          SendNotification(notification)
            .then(status => console.log('Notification status:', status))
            .catch(error => console.error('Notification error:', error));*/
          
          return response.data.order;
      } catch (error) {
          console.error('Error updating order status:', error);          
          throw error;
      }
  };  