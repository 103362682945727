import React from 'react';

interface SpinnerModalProps {
    isLoading: boolean;
    message: string;
}

const SpinnerModal: React.FC<SpinnerModalProps> = ({ isLoading, message }) => {
    if (!isLoading) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg flex flex-col items-center">
                <div className="w-8 h-8 border-4 border-blue-500 border-dotted rounded-full animate-spin mb-4"></div>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default SpinnerModal;
