import React from 'react'
import AdminLayout from '../../customer/Components/Layouts/Admin/AdminLayout'

function AdminAccount() {
  return (
    <AdminLayout>
        <div>Account Settings</div>
    </AdminLayout>
    
  )
}

export default AdminAccount