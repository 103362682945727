import { useState } from "react";
import { useForm, Controller, SubmitHandler, FieldValues} from "react-hook-form";
import { useNavigate, Link} from 'react-router-dom';
import { Button, TextField } from "@mui/material";
import AppLayout from "../customer/Components/Layouts/App/AppLayout";
import { LoginUser } from "../../infrastructure/api/User/UserAPI";
import { User } from "../../domain/Types/User";
import Swal from "sweetalert2";
import SpinnerModal from "./SpinnerModal";
import { Fcmtoken } from "../../domain/Types/Fcmtoken";
import { storeTokenData } from "../../infrastructure/api/FcmToken/TokenFcm";


const Signin = () => {

    const navigate = useNavigate();
    const win = window.sessionStorage;

    const [loginerrors, setErrors] = useState([]);
    const [connect, setConnect] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    //const [user, setUser] = useState([]);    

const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
    } = useForm();

    /*interface FormData {
        email: string;
        password: string;
        remember_me: boolean;
    }*/


    const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
        setErrors([]);
        setMessage("");
        

        const user: User = {
            id: 0,
            business_id: 0,
            firstname: "xavier",
            lastname: "xavier",
            email: data.email,
            email_verified_at: "xavier.khonje@hotmail.com",
            password: data.password,
            remember_token: "sdseweewe",
            acl: "customer",
            created_at: "12-12-12",
            updated_at: "12-12-12",
        };
      
      
          const UserLogin = async () => {
            try {
                setIsLoading(true);
                setConnect("Sign-in...");

              const data = await LoginUser(user);
              Swal.fire({
                title: 'SUCCESS',
                text: 'Login Successful',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500                
              })

              const UserFcmToken: string | null = win.getItem('FcmToken');

              if (UserFcmToken && UserFcmToken.trim() !== '') {
                const UserToken: Fcmtoken = {
                  user_id: data.id,
                  token: UserFcmToken
                };
              
                console.log(UserToken);
              
                storeTokenData(UserToken);
              } else {
                console.error('Token is not available or is empty');
              }              

              console.log(data);
        
              switch (data.acl) {
                case 'customer':
                  win.setItem('Token', data.remember_token);
                  win.setItem('userid', data.id.toString());
                  win.setItem('fname', data.firstname);
                  win.setItem('lname', data.lastname);
                  win.setItem('email', data.email);
                  win.setItem('userID', data.id.toString());
                  navigate('/Home')
                  break; 
                case 'driver':
                    win.setItem('Token', data.remember_token);
                    win.setItem('userid', data.id.toString());
                    win.setItem('fname', data.firstname);
                    win.setItem('lname', data.lastname);
                    win.setItem('email', data.email);
                    win.setItem('userID', data.id.toString());
                    navigate('/Driverhome')
                    break;                  
                case 'Administrator':
                  return setMessage('Account Not Active, Please contact Administrator');
                case 'superadmin':
                  return setMessage('Account Not Active, Please contact Administrator');
                case 'businessowner':
                    win.setItem('Token', data.remember_token);
                    win.setItem('userid', data.id.toString());
                    win.setItem('Businessid', data.business_id.toString());
                    win.setItem('fname', data.firstname);
                    win.setItem('lname', data.lastname);
                    win.setItem('email', data.email);
                    win.setItem('userID', data.id.toString());
                    console.log(data.business_id.toString());
                    navigate('/Dashboard')
                    break;  
                  case 'user':
                    return setMessage('Account Not Active, Please contact Administrator');            
                default:
                  return setMessage('Account Not Active, Please contact Administrator');    
              }
            } catch (error) {
              console.error('Error fetching data:', error);
              Swal.fire({
                title: 'ERROR',
                text: 'Login Failed',
                icon: 'error',
                showConfirmButton: true
            });
            }
            finally {
                setIsLoading(false);
            }
          };
      
          UserLogin();

          reset();
    }

  // Watch the password and confirmPassword fields for changes
 // const password = watch('password', '');
  //const confirmPassword = watch('confirmPassword', '');
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  // Add a custom validation rule to check if passwords match
  //const passwordMatch = (value: string) => value === password || 'Passwords do not match';


    return (
        <>
            <AppLayout>
                    {/*<!-- Begin Login Content Area -->*/}
                    <div className="page-section mb-60">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6">
                                    {/*<!-- Login Form s-->*/}
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="login-react-Form">
                                            <h4 className="login-title">Login</h4>
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                <span className="text-red-500">{loginerrors}{message}</span>
                                                    <Controller
                                                                    name="email"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{
                                                                        required: 'Email address is required',
                                                                        pattern: {
                                                                        value: emailRegex,
                                                                        message: 'Invalid email address',
                                                                        },
                                                                    }}
                                                                    render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id="email"
                                                                        label="Email Address"                        
                                                                        variant="outlined"
                                                                        placeholder="Email Address"
                                                                        fullWidth    
                                                                        margin="normal"                                             
                                                                        error={!!errors.email}
                                                                        helperText={errors.email && "Email is required"}
    
                                                                    />
                                                                    )}
                                                    />                                            
                                                </div>
                                                <div className="col-12">
                                                    <Controller
                                                                    name="password"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id="password"
                                                                        type="password"                                                                    
                                                                        label="Password"                        
                                                                        variant="outlined"
                                                                        placeholder="Enter Password"
                                                                        fullWidth      
                                                                        margin="normal"                                                                                                           
                                                                        error={!!errors.password}
                                                                        helperText={errors.password && "Password is required"}
                                                                    />
                                                                    )}
                                                    />                                             
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                                                        <Controller
                                                            name="remember_me"
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({ field }) => (
                                                                <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                                                                <input
                                                                    {...field}
                                                                    type="checkbox"
                                                                    id="remember_me"
                                                                    onChange={(e) => setValue("remember_me", e.target.checked)}
                                                                />
                                                                <label htmlFor="remember_me">Remember me</label>
                                                                </div>
                                                            )}
                                                        />                                                    
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-10 mb-20 text-left text-md-right">
                                                    <Link to={{pathname: `/ForgotPassword` }}> <span className="ForgotPassword">Forgotten password?</span></Link><br />
                                                    <Link to={{pathname: `/Register` }}> <span className="Register">Register</span></Link>
                                                </div>
                                                <div className="col-md-12">
                                                    <Button type="submit" className="Loginbutton mt-0" variant="contained">Login</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </AppLayout> 
            <SpinnerModal isLoading={isLoading} message={connect} /> {/* Add the SpinnerModal component here */}
        </>
      )
}

export default Signin