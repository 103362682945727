import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Card, CardContent, Typography, Button, Badge } from '@mui/material';
import AdminLayout from "../customer/Components/Layouts/Admin/AdminLayout";

// Dummy data to represent orders; replace with actual API data
const dummyOrders = [
  { id: 1, status: 'new' },
  { id: 2, status: 'inProgress' },
  { id: 3, status: 'completed' },
  { id: 4, status: 'new' },
  { id: 5, status: 'inProgress' },
];

const Dashboard = () => {
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const [inProgressOrdersCount, setInProgressOrdersCount] = useState(0);
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Replace the dummy data with your API call to fetch orders
    const fetchOrders = async () => {
      // Simulating fetching data with the dummyOrders array
      const orders = dummyOrders;

      const newOrders = orders.filter(order => order.status === 'new').length;
      const inProgressOrders = orders.filter(order => order.status === 'inProgress').length;
      const completedOrders = orders.filter(order => order.status === 'completed').length;

      setNewOrdersCount(newOrders);
      setInProgressOrdersCount(inProgressOrders);
      setCompletedOrdersCount(completedOrders);
    };

    fetchOrders();
  }, []);

  const ShowOrder = (orderType: string) => {
    navigate("/AdminOrders", { state: { orderType } });
  };  

  return (
    <AdminLayout>
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      <Grid container spacing={4}>
        {/* New Orders Section */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                <Badge badgeContent={newOrdersCount} color="primary">
                  New Orders
                </Badge>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Orders that have just been placed and need attention.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '1rem' }}
                onClick={() => ShowOrder("NewOrders")}
              >
                View New Orders
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Orders In Progress Section */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                <Badge badgeContent={inProgressOrdersCount} color="secondary">
                  Orders In Progress
                </Badge>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Orders that are currently being prepared.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: '1rem' }}
                onClick={() => ShowOrder("InProgressOrders")}
              >
                View Orders In Progress
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Completed Orders Section */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                <Badge badgeContent={completedOrdersCount} color="success">
                  Completed Orders
                </Badge>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Orders that have been completed and delivered.
              </Typography>
              <Button
                variant="contained"
                color="success"
                style={{ marginTop: '1rem' }}
                onClick={() => ShowOrder("CompletedOrders")}
              >
                View Completed Orders
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Additional Functions Section */}
      <Paper style={{ marginTop: '2rem', padding: '1rem' }}>
        <Typography variant="h6" gutterBottom>
          Additional Functions
        </Typography>
        <Button variant="outlined" color="warning" style={{ marginRight: '1rem' }}>
          Mark Items Unavailable
        </Button>
        <Button variant="outlined" color="error">
          Close Shop
        </Button>
        <Button variant="outlined" color="success" style={{ marginLeft: '1rem' }}>
          Open Shop
        </Button>
      </Paper>
    </AdminLayout>
  );
};

export default Dashboard;