import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShoppingBadge from "../../CommmonComponents/ShoppingBadge";
import { Button, Typography } from "@mui/material";

const Navigation = () => {
  const win = window.sessionStorage;
  const navigate = useNavigate()

  const handleLogout = () =>{
      win.clear()
      navigate("/")
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">FoodHut</Link>
            <ul className="navbar-nav">
              <li className="nav-item px-2"><Link to="/ShoppingCart">Shopping Cart</Link></li>
              <li className="nav-item px-2"><Link to="/MyOrders">My Orders</Link></li>
              <li className="nav-item px-2"><Link to="/Favourites">Favourites</Link></li>
            </ul>
            <Typography><span className="font-bold">Welcome:</span> {win.getItem("fname")} {win.getItem("lname")}</Typography>
            <Typography><Link to="/CustomerAccount" className="font-bold">My Account</Link></Typography>
            <Button onClick={handleLogout}>Logout</Button>
            <ShoppingBadge />
          </div>
      </nav>
    </>
  )
}

export default Navigation