import CustomerLayout from './Components/Layouts/Customer/CustomerLayout'

const Favourites = () => {
  return (
    <CustomerLayout>
    <p className="py-11">
    Favourites
    </p>
</ CustomerLayout>
  )
}

export default Favourites