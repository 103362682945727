import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDPvnv2F-OAMevjIyO2LkLLE1eVkMXERx8",
  authDomain: "fcmfoodhut.firebaseapp.com",
  databaseURL: "https://fcmfoodhut-default-rtdb.firebaseio.com",
  projectId: "fcmfoodhut",
  storageBucket: "fcmfoodhut.appspot.com",
  messagingSenderId: "450288019274",
  appId: "1:450288019274:web:a617afbd332b1e201003b3",
  measurementId: "G-S3KVLLSCBY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const database = getDatabase(app);

// Initialize FCM
const messaging = getMessaging(app);
const vapidKey = "BD3masS30yMWSOQm6Ey_IgREjKHHcPhb_yJefupR2a1KFVVrw45XV_g5g1KmJ7wJMiz8y5I12PAPkhrbmaPLyQ4";

// Export services
export { database, ref, set, get, push, onValue, messaging, getToken, onMessage, vapidKey };