import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './foodhut.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ForgotPassword from './Presentation/app/ForgotPassword';
import LandingScreen from './Presentation/app/LandingScreen';
import Home from './Presentation/customer/Home';
import Driverhome from './Presentation/driver/Driverhome';
import Dashboard from './Presentation/admin/Dashboard';
import FoodStore from './Presentation/customer/FoodStore';
import MenuOptions from './Presentation/customer/MenuOptions';
import ShoppingCartPage from './Presentation/customer/ShoppingCartPage';
import CheckOut from './Presentation/customer/CheckOut';
import PaymentConfirmation from './Presentation/customer/PaymentConfirmation';
import MyOrders from './Presentation/customer/MyOrders';
import Favourites from './Presentation/customer/Favourites';
import TrackOrder from './Presentation/customer/TrackOrder';
import Context from './utilities/Context/Context';
import CartProvier from './utilities/Context/ShoppingCartContext';
import Signin from './Presentation/app/Signin';
import Register from './Presentation/app/Register';
import PaymentPreferences from './Presentation/app/PaymentPreferences';
import FoodStoreCategory from './Presentation/customer/FoodStoreCategory';
import Account from './Presentation/customer/Account';
import UpdateDeliveryInfo from './Presentation/customer/MyAccount/UpdateDeliveryInfo';
import UpdatePaymentInfo from './Presentation/customer/MyAccount/UpdatePaymentInfo';
import UpdatePersonalInformation from './Presentation/customer/MyAccount/UpdatePersonalInformation';
import ExtendedSearch from './Presentation/customer/ExtendedSearch';
import AdminOrders from './Presentation/admin/Pages/AdminOrders';
import AdminAccount from './Presentation/admin/Pages/AdminAccount';
import FoodCategory from './Presentation/app/FoodCategory';
import ProductPage from './Presentation/app/ProductPage';
import MenuFeedback from './Presentation/customer/MenuFeedback';
import Reviews from './Presentation/customer/Reviews';
import { messaging, getToken, vapidKey }  from './utilities/firebaseConfig'; 
import { onMessage } from 'firebase/messaging';
import { MessagePayload } from 'firebase/messaging';

const win = window.sessionStorage;

// Register Service Worker for FCM
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      return navigator.serviceWorker.ready;
    })
    .then((registration) => {
      return getToken(messaging, { vapidKey });
    })
    .then((token) => {
      console.log('FCM Token:', token);
      win.setItem('FcmToken', token);
    })
    .catch((error) => {
      console.error('Service Worker registration or FCM token retrieval failed:', error);
    });
}


// Extend the type to include click_action
interface CustomNotificationPayload extends MessagePayload {
  notification?: {
    title?: string;
    body?: string;
    icon?: string;
    click_action?: string;
  };
  data?: {
    click_action?: string;
  };
}

// Handle foreground notifications
onMessage(messaging, (payload: CustomNotificationPayload) => {
  console.log('Foreground message received:', payload);

  const notificationTitle = payload.notification?.title || 'Notification';
  const notificationOptions = {
    body: payload.notification?.body,
    icon: payload.notification?.icon || '/default-icon.png',
  };

  const clickAction = payload.notification?.click_action || payload.data?.click_action || 'https://www.foodhut.mw/';

  if (Notification.permission === 'granted') {
    const notification = new Notification(notificationTitle, notificationOptions);

    notification.onclick = () => {
      window.location.href = clickAction;
    };
  }
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CartProvier>
      <Context>
        <Router>
          <Routes>
            <Route path="/" element={<LandingScreen />} />
            <Route path="/FoodCategory" element={<FoodCategory />} />
            <Route path="/ProductPage" element={<ProductPage />} />
            <Route path="/MenuFeedback" element={<MenuFeedback />} />
            <Route path="/Reviews" element={<Reviews />} />
            <Route path="/Signin" element={<Signin />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/PaymentPreferences" element={<PaymentPreferences />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/ExtendedSearch" element={<ExtendedSearch />} />
            <Route path="/CustomerAccount" element={<Account />} />
            <Route path="/PersonalInfo" element={<UpdatePersonalInformation />} />
            <Route path="/PaymentInfo" element={<UpdatePaymentInfo />} />
            <Route path="/DeliveryInfo" element={<UpdateDeliveryInfo />} />
            <Route path="/Driverhome" element={<Driverhome />} />
            <Route path="/FoodStores/:BusinessID" element={<FoodStore />} />
            <Route path="/FoodStoreCategory/:Category" element={<FoodStoreCategory />} />
            <Route path="/MenuOptions" element={<MenuOptions />} />
            <Route path="/ShoppingCart" element={<ShoppingCartPage />} />
            <Route path="/Checkout" element={<CheckOut />} />
            <Route path="/PaymentConfirmation" element={<PaymentConfirmation />} />
            <Route path="/MyOrders" element={<MyOrders />} />
            <Route path="/TrackOrder" element={<TrackOrder />} />
            <Route path="/Favourites" element={<Favourites />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/AdminOrders" element={<AdminOrders />} />
            <Route path="/AdminAccount" element={<AdminAccount />} />
          </Routes>
        </Router>
      </Context>
    </CartProvier>
  </React.StrictMode>
);