import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Rating } from '@mui/material';
import { Review } from '../../domain/Types/Review';
import { SaveMenuReview } from '../../infrastructure/api/Review/ReviewAPI';
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';

const MenuFeedback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { menu_id, order_number, item_name, item_description, item_price } = location.state || {};
    const win = window.sessionStorage;

    const userIdString = win.getItem("userid");
    const FirstName = win.getItem("fname");
    const userId = userIdString ? parseInt(userIdString) : 0;

    // State to manage the review form fields
    const [ratingValue, setRatingValue] = useState<number | null>(0);
    const [reviewBody, setReviewBody] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        // Format the date to match MySQL DATETIME format
        const now = new Date();
        const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    
        // Create review object
        const review: Review = {
            menu_id: menu_id,
            user_id: userId, // Assuming user ID is available through context or other means
            rating_value: ratingValue || 0,
            author_name: FirstName || "", // This should be dynamically set based on logged-in user
            date_published: formattedDate, // Use the formatted date
            review_body: reviewBody,
        };
    
        setLoading(true);
        setError(null);
    
        try {
            // Save review using the API
            await SaveMenuReview(review);
    
            // Redirect back to the order tracking page
            navigate('/TrackOrder', { state: { order_number } });
        } catch (error) {
            console.error('Error saving review:', error);
            setError('Failed to submit your review. Please try again.');
        } finally {
            setLoading(false);
        }
    };    

    return (
        <CustomerLayout>
        <Box sx={{ padding: 2 }}>
            <Typography variant="h5">Provide Feedback for {item_name}</Typography>
            <Typography variant="subtitle1">{item_description}</Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                Price: MK {item_price}
            </Typography>
            <form onSubmit={handleSubmit}>
                <Typography component="legend">Rating</Typography>
                <Rating
                    name="rating"
                    value={ratingValue}
                    onChange={(event, newValue) => setRatingValue(newValue)}
                    precision={0.5} // Allows half-star ratings
                    max={5} // Maximum rating value
                />
                <TextField
                    label="Review"
                    value={reviewBody}
                    onChange={(e) => setReviewBody(e.target.value)}
                    required
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
                {error && <Typography color="error">{error}</Typography>}
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit Feedback'}
                </Button>
            </form>
        </Box>
        </CustomerLayout>
    );
};

export default MenuFeedback;