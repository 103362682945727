import { useEffect, useState } from "react";
import AppLayout from "../customer/Components/Layouts/App/AppLayout";
import { requestFCMToken } from "../../utilities/firebase";
import { Box, Grid, Card, CardContent, CardMedia, Badge, Typography, IconButton, Rating, Tooltip, Skeleton } from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { fetchPopularMenus } from "../../infrastructure/api/Menus/MenuAPI";
import { GeoSearch } from "../../domain/Types/GeoSearch";
import { Menu } from "../../domain/Types/Menu";
import { ShoppingCartState } from "../../utilities/Context/ShoppingCartContext";
import { ShoppingCart } from "../../domain/Types/ShoppingCart";
import AddToCartModal from "./GeneralComponents/AddToCartModal";
import ShoppingCartFloat from "../customer/Components/OtherComponents/ShoppingCartFloat";

const LandingScreen = () => {
  const [fcmToken, setFcmToken] = useState("");
  const [foodItems, setfoodItems] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [openModal, setOpenModal] = useState(false); 
  const [selectedItem, setSelectedItem] = useState<Menu | null>(null); 
  const win = window.sessionStorage;
  const navigate = useNavigate();
  const { dispatch } = ShoppingCartState();

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;  

  const handleAddtoCart = (menu: Menu) => {
    const cartitem: ShoppingCart = {
      id: menu.id,
      user_id: userId,
      session_id: menu.business_id,
      order_id: 1,
      menu_id: menu.id,
      imagepath: menu.image_path,
      status: menu.description,
      quantity: 1,
      total: menu.price,
      name: menu.name,
      price: menu.price,
      productattributevalues: [],
      businessId: menu.business_id,
      businessname: menu.business.name
    };

    dispatch({ type: 'ADD_TO_CART', item: cartitem });
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchFCMToken = async () => {
      try {
        const token = await requestFCMToken();
        setFcmToken(token);
        localStorage.setItem('fcmToken', token || '');
      } catch (error) {
        console.error("Failed to get FCM Token", error);
      }
    };
  
    const search: GeoSearch = {
      DeliveryAddress: "",
      Category: 0,
      Latitude: -15.794176,
      Longitude: 35.025715,
      StartingPrice: 500,
      EndingPrice: 2000,
      Radius: 10
    };
  
    const getPopularMenus = async (search: GeoSearch) => {
      try {
        const data = await fetchPopularMenus(search);
        setfoodItems(data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching popular menus", error);
      }
    };
  
    fetchFCMToken();
    getPopularMenus(search); 
  
  }, []);  

  const handleOpenModal = (item: Menu) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  const handleBadgeClick = () => {
    navigate(`/ProductPage`);
  };

  const FoodTile = ({ item }: { item: typeof foodItems[0] }) => {
    return (
      <Card
        sx={{
          borderRadius: '16px 16px 8px 8px',
        }}
      >
        <Box position="relative">
          <Badge
            badgeContent={`MK${item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            color="primary"
            sx={{
              position: 'absolute',
              top: 15,
              left: 30,
              zIndex: 1,
              padding: '0.5rem',
              borderRadius: '8px',
              backgroundColor: '#1976d2',
              color: 'white',
            }}
          />
          <IconButton
            aria-label="add to cart"
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              zIndex: 1,
              backgroundColor: '#ffffff',
            }}
            onClick={() => handleOpenModal(item)}
          >
            <AddShoppingCart />
          </IconButton>
          <CardMedia
            component="img"
            image={`https://app.foodhut.mw/Admin/public/images/${item.image_path}`}
            alt={item.name}
            sx={{
              height: 140,
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Box>
        <CardContent sx={{ padding: 1 }}>
          <Typography variant="h6" component="div" sx={{ marginBottom: 0.5 }}>
            {item.name}
          </Typography>
          <Rating value={item.average_rating} precision={0.5} readOnly sx={{ marginBottom: 0.5 }}/>
          <Box mt={0}>
            <Typography variant="h6" component="div" color="textPrimary" sx={{ marginBottom: 0.5 }}>
              MK {item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="textSecondary"
              sx={{ textDecoration: 'line-through', marginBottom: 0 }}
            >
              MK {item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const FoodTiles = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };

    return (
      <div>
      <span className="pl-5 font-bold"><Typography variant="h6">Trending Menus</Typography></span>
      <Grid container spacing={2}>        
        <Grid item xs={12} sm={9}>
          <Slider {...settings}>
            {loading ? (
              Array.from(new Array(4)).map((_, index) => (
                <Box key={index} sx={{ padding: 2 }}>
                  <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: '5%' }} />
                  <Skeleton width="60%" />
                  <Skeleton width="40%" />
                </Box>
              ))
            ) : (
              foodItems.map((item) => (
                <div key={item.id}>
                  <Box sx={{ padding: 2 }}>
                    <FoodTile item={item} />
                  </Box>
                </div>
              ))
            )}
          </Slider>
        </Grid>
        <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Box sx={{ height: '100%'}}>
            <ShoppingCartFloat />
          </Box>
        </Grid>        
      </Grid>
      </div>
    );
  };

  const handleCategoryClick = (categoryName: string) => {
    console.log(categoryName);
    navigate('/FoodCategory', { state: { categoryName } });
  };  

  const ScrollingItems = () => {
    return (
      <Box>
        <Typography variant="h4" sx={{ padding: 2 }}>
          Categories
        </Typography>
        <Box sx={{ display: 'flex', overflowX: 'auto', padding: 2 }}>
          {loading ? (
            Array.from(new Array(5)).map((_, index) => (
              <Skeleton key={index} variant="circular" width={80} height={80} sx={{ marginRight: 2 }} />
            ))
          ) : (
            foodItems.map((item) => (
              <Tooltip key={item.id} title={item.name} arrow>
                <Box
                  onClick={() => handleCategoryClick(item.name)}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    marginRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 3,
                    cursor: 'pointer',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`https://app.foodhut.mw/Admin/public/images/${item.image_path}`}
                    alt={item.name}
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
              </Tooltip>
            ))
          )}
        </Box>
      </Box>
    );
  };

  const MoreTiles = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          {loading ? (
            Array.from(new Array(4)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" sx={{borderRadius: '5%'}} width="100%" height={200} />
                <Skeleton width="60%" />
                <Skeleton width="40%" />
              </Grid>
            ))
          ) : (
            foodItems.map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <FoodTile item={item} />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    );
  };

  return (
    <AppLayout>
      <Box sx={{ marginTop: 2 }}>
        <FoodTiles />
        {/*<ScrollingItems />*/}
        <Typography variant="h4" className="font-bold mt-3" sx={{ padding: 2 }}>Popular Menus</Typography>
        <MoreTiles />
      </Box>
      {selectedItem && (
      <AddToCartModal 
      open={openModal} 
      handleClose={handleCloseModal}
      selectedItem={selectedItem} 
      userId={userId}
/>  
      )}
    </AppLayout>
  );
};

export default LandingScreen;