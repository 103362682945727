import React, { useState, useEffect } from 'react';
import { Box, IconButton, TextField, Button, Typography, Chip } from '@mui/material';
import { Chat, Close } from '@mui/icons-material';
import { database, ref, set, push, onValue } from '../../../utilities/firebaseConfig'; // Update the path if needed
import { DataSnapshot } from 'firebase/database'; // Import DataSnapshot
import { Chat as ChatType } from '../../../domain/Types/Chat';

interface ChatBoxProps {
  orderNumber: string; // Receive the order number as a prop
}

const ChatBox: React.FC<ChatBoxProps> = ({ orderNumber }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatType[]>([]);

  const win = window.sessionStorage;
  const userID = win.getItem('userID');
  const currentUser = userID ? parseInt(userID, 10) : 0;

  // Reference the chat for a specific order
  const chatRef = ref(database, orderNumber);

  useEffect(() => {
    const unsubscribe = onValue(chatRef, (snapshot: DataSnapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert the object to an array of ChatType objects
        const messages: ChatType[] = Object.values(data);
        setChatHistory(messages);
      }
    });

    return () => unsubscribe();
  }, [orderNumber]); // Re-run effect when orderNumber changes

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const userId = userID ? parseInt(userID, 10) : 0;
      const direction = 1; // Assuming 1 is for sent messages
      const time = new Date().toISOString();

      const newMessage: ChatType = {
        id: Date.now(),
        userid: userId,
        message: message,
        time: time,
        direction: direction
      };

      const newMessageRef = push(chatRef);
      set(newMessageRef, newMessage)
        .then(() => {
          setMessage('');
        })
        .catch((error) => {
          console.error('Error sending message:', error);
        });
    }
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
      {isOpen ? (
        <Box
          sx={{
            width: 300,
            height: 400,
            bgcolor: 'white',
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Chat</Typography>
            <IconButton onClick={toggleChat}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', mt: 2 }}>
            {chatHistory.map((msg) => (
              <Box
                key={msg.id}
                sx={{
                  display: 'flex',
                  flexDirection: msg.userid === currentUser ? 'row-reverse' : 'row',
                  mb: 2,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    maxWidth: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: msg.userid === currentUser ? 'flex-end' : 'flex-start',
                  }}
                >
                  <Chip
                    label={msg.message}
                    sx={{
                      bgcolor: msg.userid === currentUser ? 'primary.main' : 'grey.300',
                      color: msg.userid === currentUser ? 'white' : 'black',
                      borderRadius: 2,
                      mb: 0.5,
                    }}
                  />
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {new Date(msg.time).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message"
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
          />
          <Button variant="contained" color="primary" onClick={handleSendMessage}>
            Send
          </Button>
        </Box>
      ) : (
        <IconButton
          sx={{ bgcolor: 'primary.main', color: 'white' }}
          onClick={toggleChat}
        >
          <Chat />
        </IconButton>
      )}
    </Box>
  );
};

export default ChatBox;