import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminLayout from "../../customer/Components/Layouts/Admin/AdminLayout";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SpinnerModal from '../../app/SpinnerModal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { OrderSearch } from "../../../domain/Types/OrderSearch";
import { SearchOrders, UpdateOrderStatus } from '../../../infrastructure/api/Order/OrderAPI';
import { DetailedOrder } from '../../../domain/Types/DetailedOrder';
import Swal from 'sweetalert2';
import ChatBox from '../../app/GeneralComponents/ChatBox';

function AdminOrders() {
  const win = window.sessionStorage;
  const [orders, setOrders] = useState<DetailedOrder[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedOrder, setSelectedOrder] = useState<DetailedOrder | null>(null); // State to keep track of the selected order for chat

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const businessIdString = win.getItem("Businessid");
  const businessId = businessIdString ? parseInt(businessIdString) : 0;

  const navigate = useNavigate();
  const location = useLocation();
  
  // Extract the order type from the query parameter
  const orderType = location.state?.orderType;

  // Set the search criteria based on the order type
  const getOrderStatus = () => {
    switch(orderType) {
      case "NewOrders":
        return ["Order Confirmed"];
      case "InProgressOrders":
        return ["Preparing", "Delayed", "Packaged", "Ready for collection"];
      case "CompletedOrders":
        return ["Collected", "Out for Delivery", "Delivered"];
      default:
        return [];
    }
  };

  const ordersearch: OrderSearch = {
    id: 1,
    user_id: null,
    business_id: businessId,
    status: getOrderStatus(), // Use the status based on the order type
    order_number: "",
    contact_phone: "",
    total_amount: "",
    delivery_address: "",
    delivery_time: "",
    latitude: "",
    longitude: "",
    note: "",
    cartsession: "",
    created_at: "",
    updated_at: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await SearchOrders(ordersearch);
        setOrders(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [orderType]); // Add orderType as a dependency to refetch if it changes

  const handleTrackOrder = (order: DetailedOrder) => {
    navigate("/TrackOrder", { state: { order } });
  };

  const handleStatusChange = async (orderId: number, newStatus: string) => {
    try {
        await UpdateOrderStatus(orderId, newStatus);
        setOrders((prevOrders) =>
            prevOrders
                ?.map((order) => {
                    if (order.id === orderId) {
                        const updatedOrderStatus = {
                            ...order.orderstatus,
                            status: newStatus,
                            id: order.orderstatus?.id ?? null,
                            description: order.orderstatus?.description ?? null,
                        };

                        return {
                            ...order,
                            orderstatus: updatedOrderStatus,
                        };
                    }
                    return order;
                })
                ?.filter((order) => getOrderStatus().includes(order.orderstatus?.status || "")) ?? null
        );

        Swal.fire({
            title: 'Success!',
            text: 'Order status has been updated successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500                
          })
    } catch (error) {
        console.error('Error updating status:', error);
    }
};

  const availableStatuses = [
    "Order Confirmed",
    "Preparing",
    "Delayed",
    "Packaged",
    "Ready for collection",
    "Collected",
    "Out for Delivery",
    "Delivered",
  ];

  return (
    <AdminLayout>
      <SpinnerModal isLoading={loading} message="Loading your orders..." />
      {!loading && orders && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell align="right">Order Date</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Total Amount</TableCell>
                <TableCell align="right">Track Order</TableCell>
                <TableCell align="right">Chat</TableCell> {/* Add Chat column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{order.order_number}</TableCell>
                  <TableCell align="right">{order.created_at}</TableCell>
                  <TableCell align="right">
                    <Select
                      value={order.orderstatus?.status || ""}
                      onChange={(e) => handleStatusChange(order.id!, e.target.value)}
                      disabled={availableStatuses.indexOf(order.orderstatus?.status || "") >= availableStatuses.length - 1}
                    >
                      {availableStatuses.map((status) => (
                        <MenuItem
                          key={status}
                          value={status}
                          disabled={availableStatuses.indexOf(status) <= availableStatuses.indexOf(order.orderstatus?.status || "")}
                        >
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell align="right">{order.total_amount}</TableCell>
                  <TableCell align="right">
                    <button onClick={() => handleTrackOrder(order)}>Track Order</button>
                  </TableCell>
                  <TableCell align="right">
                    <button onClick={() => setSelectedOrder(order)}>Open Chat</button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Render ChatBox if an order is selected */}
      {selectedOrder && (
        <ChatBox orderNumber={selectedOrder.order_number || ''} />
      )}
    </AdminLayout>
  );
}

export default AdminOrders;