import { useParams, useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBadge from '../../CommmonComponents/ShoppingBadge';
import FoodStoreSearchForm from '../../Forms/FoodStoreSearchForm';
import { useState, useEffect } from 'react';
import { Category } from '../../../../../domain/Types/Category';
import { Button, Stack, useMediaQuery, useTheme, Skeleton, AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FoodhutChip from '../../OtherComponents/FoodhutChip';
import { fetchCategoriesData } from '../../../../../infrastructure/api/Category/CategoryAPI';
import { GeoSearch } from '../../../../../domain/Types/GeoSearch';
import { fetchProxBusiness } from '../../../../../infrastructure/api/BusinessAddress/BusinessAddressAPI';
import { AddressBizDetailed } from '../../../../../domain/Types/AddressBizDetailed';
import { fetchAllAddressedBusiness } from '../../../../../infrastructure/api/Business/BusinessAPI';
import SigninModal from '../../../../app/GeneralComponents/SigninModal';
import styled from 'styled-components';
import Swal from "sweetalert2";

const AppNavigation = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [latLng, setLatLng] = useState<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });
  const [radius, setRadius] = useState<number>(0);
  const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
  const [showAllChips, setShowAllChips] = useState(false);
  const [category, setCategory] = useState<Category[] | null>(null);
  const displayedCategories = showAllChips ? category : category?.slice(0, 15);
  const [loading, setLoading] = useState<boolean>(true);
  const { Category: initialFoodstorecat } = useParams<{ Category: string }>();
  const [foodstorecat, setFoodstorecat] = useState<string | undefined>(initialFoodstorecat);
  const [businessAdd, setBusinessAdd] = useState<AddressBizDetailed[] | null>(null);
  const [isSigninModalOpen, setIsSigninModalOpen] = useState(false);
  const [actionOnSuccess, setActionOnSuccess] = useState<() => void>(() => () => {});
  const win = window.sessionStorage;

  const userIdString = win.getItem("userid");
  const userId = userIdString ? parseInt(userIdString) : 0;

  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleFoodhutCategory = (categoryName: string) => {
    setLoading(true);
    setSearchTriggered(true);
    setFoodstorecat(categoryName);
  };

  const handleCategoryClick = (categoryName: string, categoryId: number) => {
    navigate('/FoodCategory', { state: { categoryName, categoryId } });
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const fetchBusinessesByLocation = async (latitude: number, longitude: number, radius: number) => {
    const search: GeoSearch = {
      DeliveryAddress: "",
      Category: 0,
      Latitude: latitude,
      Longitude: longitude,
      StartingPrice: 0,
      EndingPrice: 0,
      Radius: radius
    };

    try {
      const data = await fetchProxBusiness(search);
      setBusinessAdd(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllBusinesses = async () => {
    try {
      const data = await fetchAllAddressedBusiness();
      setBusinessAdd(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await fetchCategoriesData();
        setCategory(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();

    if (navigator.geolocation && radius > 0) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude });
          fetchBusinessesByLocation(latitude, longitude, radius);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          fetchAllBusinesses();  // Fallback to full search if geolocation fails
        }
      );
    } else {
      fetchAllBusinesses();  // Fallback to full search if geolocation is not supported or radius is 0
      if (radius > 0) console.log("Geolocation is not supported by this browser.");
    }
  }, [radius, foodstorecat]);

  useEffect(() => {
    if (searchTriggered) {
      if (latLng.lat !== null && latLng.lng !== null && radius > 0) {
        fetchBusinessesByLocation(latLng.lat, latLng.lng, radius);
      } else {
        fetchAllBusinesses();
      }
    }
  }, [foodstorecat, searchTriggered]);

  const customColor = '#e88824';

  const StyledButton = styled.button`
    background-color: black;
    color: white;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${customColor};
    }
  `;

  const openSigninModal = (action: () => void) => {
    setActionOnSuccess(() => action);
    setIsSigninModalOpen(true);
  };

  const closeSigninModal = () => {
    setIsSigninModalOpen(false);
  };

  // Function to handle navigation or opening sign-in modal
  /*const handleAccountClick = () => {
    if (userId === 0) {
      // If userId is not set, show SignInModal
      openSigninModal();
    } else {
      // If userId is set, navigate to /CustomerAccount
      handleNavigate('/CustomerAccount');
    }
  };*/

  /*const handleSignInFeedback = (success: any) => {
    if (success) {
      // Do something unique in this component on sign-in success
      navigate('/CustomerAccount');
    } else {
      // Handle failure
      Swal.fire('Sign-in Failed', 'Please try again.', 'error');
    }
  };*/

  const handleSignInSuccess = () => {
    if (actionOnSuccess) {
      actionOnSuccess(); // Execute the action passed to SigninModal
    }
    closeSigninModal(); // Close the modal
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    toggleDrawer(false); // Close the drawer after navigation
  };

  const handleURLNavigate = (path: string) => {
    if (userId === 0) {
      openSigninModal(() => handleNavigate(path)); // Pass navigation as an action
    } else {
      handleNavigate(path);
    }
  }

  const handleAccountClick = () => {
    if (userId === 0) {
      openSigninModal(() => handleNavigate('/CustomerAccount')); // Pass navigation as an action
    } else {
      handleNavigate('/CustomerAccount');
    }
  };

  const handleAnotherAction = () => {
    // Define another action, e.g., navigate to a different page or execute some logic
  };  

  return (
    <>
    <div className="container">
      {/* MUI AppBar with Drawer */}
      <AppBar
        position="relative" // Make it non-sticky
        sx={{ backgroundColor: '#FFFFFF', boxShadow: 'none' }} // Remove shadow
      >
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ color: {customColor}, mr: 2 }} // Set color to orange
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>FoodHut</Link>
          </Typography>
          <ShoppingBadge />
        </Toolbar>
      </AppBar>

      {/* MUI Drawer */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={() => handleNavigate('/')}>
              <ListItemText primary="Signin" />
            </ListItem>
            <ListItem button onClick={() => handleNavigate('/Register')}>
              <ListItemText primary="Register" />
            </ListItem>
            <ListItem button onClick={() => handleURLNavigate("/Favourites") }>
              <ListItemText primary="Favourites" />
            </ListItem>
            <ListItem button onClick={() => handleURLNavigate("/Home") }>
              <StyledButton>FoodStores near you</StyledButton>
            </ListItem>
            <ListItem button onClick={() => handleURLNavigate("/MyOrders") }>
              <ListItemText primary="My Orders" />
            </ListItem>
            <ListItem button onClick={handleAccountClick}> {/* If win UserId is not set Popup signin Modal - else Navigate */}
              <ListItemText primary="My Account" />
            </ListItem>            
          </List>
        </Box>
      </Drawer>

      <div className="container">
        <FoodStoreSearchForm onSubmit={(data) => {
          setRadius(data.radius);
          setSearchTriggered(true);
        }} />

        <Stack
          direction="row"
          spacing={isXs ? 0.25 : isSm ? 0.5 : 1}
          className={`flex-wrap gap-2 ${isXs || isSm ? 'justify-center' : 'justify-start'}`}
        >
          {loading
            ? Array.from(new Array(18)).map((_, index) => (
                <Skeleton key={index} variant="rectangular" animation="wave" width={120} height={30} sx={{ borderRadius: "12px 12px 12px 12px" }} />
              ))
            : displayedCategories?.map((cat: Category) => (
                <FoodhutChip
                  Category={cat.name}
                  key={cat.id}
                  onSelectCategory={() => handleCategoryClick(cat.name, cat.id)}
                  imageUrl={cat.image_path}
                />
              ))}
          {category && category.length > 5 && (
            <Button onClick={() => setShowAllChips(!showAllChips)}>
              {showAllChips ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </Stack>
      </div>
    </div>
    <SigninModal open={isSigninModalOpen} handleClose={closeSigninModal} 
      onSignInFeedback={handleSignInSuccess}      
    />
    </>
  );
};

export default AppNavigation;