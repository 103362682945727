import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { Fcmtoken } from "../../../domain/Types/Fcmtoken";
import { BASE_URL } from "../../../domain/Types/Config";
import { FcmNotification } from "../../../domain/Types/FcmNotification";

axiosRetry(axios, {
    retries: 3, // Number of retries
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
    shouldResetTimeout: true // Reset timeout on retries
  });
  
  export const storeTokenData = async (token: Fcmtoken): Promise<Fcmtoken[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return storeTokenData(token); // Retry fetching data
      }
  
      const url = `${BASE_URL}/fcmtoken`;
      const response: AxiosResponse<Fcmtoken[]> = await axios.post(url, token);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const SendNotification = async (notification: FcmNotification): Promise<string> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return await SendNotification(notification); // Retry sending the notification
      }
  
      const url = `${BASE_URL}/sendPushNotification`;
      const response: AxiosResponse<{ status: string }> = await axios.post(url, notification);
  
      // Return the status message from the response
      return response.data.status;
    } catch (error) {
      console.error('Error sending notification:', error);
  
      // Return an error message string
      return 'Error sending notification';
    }
  };