import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

interface Props {
    Category: string;
    onSelectCategory: (category: string) => void;
    imageUrl?: string;  // Make imageUrl optional
}

const FoodhutChip = ({ Category, onSelectCategory, imageUrl }: Props) => {
  return (
    <Chip 
      label={Category} 
      onClick={() => onSelectCategory(Category)}
      avatar={imageUrl ? <Avatar src={`https://app.foodhut.mw/Admin/public/images/${imageUrl}`} sx={{ marginLeft: '8px' }} /> : undefined} 
    />
  );
}

export default FoodhutChip;