import AdminNavigation from "./AdminNavigation";
import Footer from "../Customer/Footer";
import Divider from '@mui/material/Divider';
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";

const AdminLayout = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => {
  return (
    <>
    <div className="container">
      <div className="row">
      <AdminNavigation />
      </div>
      {props.children}
      <Divider orientation="horizontal" flexItem />
      <div className="row">
        <Footer />
      </div>
    </div>
    </>
  )
}

export default AdminLayout