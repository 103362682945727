import { useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import CustomerLayout from '../Components/Layouts/Customer/CustomerLayout';
import { Link } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { Payment } from '../../../domain/Types/Payment';

function UpdatePaymentInfo() {
  const win = window.sessionStorage;
  
  const [payment, setPayment] = useState<Payment>({
    id: 0,
    paymentoption_id: 1, // Default payment option
    amount: 0,
    description: '',
    payment_date: '',
    status: 'Pending',
    transaction_id: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<number | string>) => {
    const { name, value } = e.target;
    setPayment((prevPayment) => ({
      ...prevPayment,
      [name as string]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Submit the updated payment information to your backend or handle it accordingly
    console.log('Updated Payment Information:', payment);
  };

  return (
    <CustomerLayout>
      <span className="font-bold">Payment Information</span>
      <br />
      Welcome {win.getItem("fname")} {win.getItem("lname")}
      <ul>
        <li><Link to="/PersonalInfo">Personal Information</Link></li>
        <li><Link to="/PaymentInfo">Payment Information</Link></li>
        <li><Link to="/DeliveryInfo">Delivery Information</Link></li>
      </ul>

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="payment-option-label">Payment Option</InputLabel>
          <Select
            labelId="payment-option-label"
            name="paymentoption_id"
            value={payment.paymentoption_id}
            onChange={handleSelectChange}
          >
            <MenuItem value={1}>Option 1</MenuItem>
            <MenuItem value={2}>Option 2</MenuItem>
            <MenuItem value={3}>Option 3</MenuItem>
          </Select>
          <FormHelperText>Select a payment option</FormHelperText>
        </FormControl>

        <TextField
          label="Amount"
          type="number"
          name="amount"
          value={payment.amount}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Description"
          name="description"
          value={payment.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Payment Date"
          type="date"
          name="payment_date"
          value={payment.payment_date}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />

        <TextField
          label="Transaction ID"
          name="transaction_id"
          value={payment.transaction_id}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            name="status"
            value={payment.status}
            onChange={handleSelectChange}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Failed">Failed</MenuItem>
          </Select>
          <FormHelperText>Select payment status</FormHelperText>
        </FormControl>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update Payment Information
        </Button>
      </form>
    </CustomerLayout>
  );
}

export default UpdatePaymentInfo;