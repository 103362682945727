import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Business } from "../../domain/Types/Business";
import { fetchCategoriesData } from '../../infrastructure/api/Category/CategoryAPI';
import { fetchBusinessbyCategory, fetchBusinessData } from '../../infrastructure/api/Business/BusinessAPI';
import { fetchAllAddressedBusiness } from '../../infrastructure/api/Business/BusinessAPI';
import { Category } from "../../domain/Types/Category";
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import FoodhutChip from "./Components/OtherComponents/FoodhutChip";
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button, Grid, CircularProgress, Typography, Skeleton } from '@mui/material'; // Import Skeleton
import BusinessCard from './Components/OtherComponents/BusinessCard';
import ShoppingCartFloat from './Components/OtherComponents/ShoppingCartFloat';
import { Search } from '../../domain/Types/Search';
import FoodStoreSearchForm from './Components/Forms/FoodStoreSearchForm';
import { fetchProxBusiness } from '../../infrastructure/api/BusinessAddress/BusinessAddressAPI';
import { GeoSearch } from '../../domain/Types/GeoSearch';
import { AddressBizDetailed } from '../../domain/Types/AddressBizDetailed';
import AppLayout from './Components/Layouts/App/AppLayout';

const Home = () => {
  const { Category: initialFoodstorecat } = useParams<{ Category: string }>();
  const [business, setBusiness] = useState<Business[] | null>(null);
  const [businessAdd, setBusinessAdd] = useState<AddressBizDetailed[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategory] = useState<Category[] | null>(null);
  const [showAllChips, setShowAllChips] = useState(false);
  const [foodstorecat, setFoodstorecat] = useState<string | undefined>(initialFoodstorecat);
  const [latLng, setLatLng] = useState<{ lat: number | null; lng: number | null }>({ lat: null, lng: null });
  const [radius, setRadius] = useState<number>(0); // default radius to 0
  const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchBusinessesByLocation = async (latitude: number, longitude: number, radius: number) => {
    const search: GeoSearch = {
      DeliveryAddress: "",
      Category: 0,
      Latitude: latitude,
      Longitude: longitude,
      StartingPrice: 0,
      EndingPrice: 0,
      Radius: radius
    };

    try {
      const data = await fetchProxBusiness(search);
      setBusinessAdd(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllBusinesses = async () => {
    try {
      const data = await fetchAllAddressedBusiness();
      console.log(data);
      setBusinessAdd(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await fetchCategoriesData();
        setCategory(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();

    if (navigator.geolocation && radius > 0) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatLng({ lat: latitude, lng: longitude });
          fetchBusinessesByLocation(latitude, longitude, radius);
        },
        (error) => {
          console.error("Error obtaining location:", error);
          fetchAllBusinesses();  // Fallback to full search if geolocation fails
        }
      );
    } else {
      fetchAllBusinesses();  // Fallback to full search if geolocation is not supported or radius is 0
      if (radius > 0) console.log("Geolocation is not supported by this browser.");
    }
  }, [radius, foodstorecat]);

  useEffect(() => {
    if (searchTriggered) {
      if (latLng.lat !== null && latLng.lng !== null && radius > 0) {
        fetchBusinessesByLocation(latLng.lat, latLng.lng, radius);
      } else {
        fetchAllBusinesses();
      }
    }
  }, [foodstorecat, searchTriggered]);

  const handleFoodStoreselect = (foodstore: Business) => {
    navigate(`/FoodStores/${foodstore.id}`);
  };

  const handleFoodhutCategory = (categoryName: string) => {
    setLoading(true);
    setSearchTriggered(true);
    setFoodstorecat(categoryName);
  };

  const displayedCategories = showAllChips ? category : category?.slice(0, 18);

  return (
    <AppLayout>
      <div className="font-black mb-2">
        {foodstorecat}
        <span className='font-medium px-2'>
          {radius === 0 ? 'Best Food Places available' : 'Best Food Places Within:'}
        </span>
        {radius !== 0 && (
          <span className='font-bold'>{radius} KM</span>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item md={9} container direction="row" spacing={2}>
          {loading ? (
            Array.from(new Array(8)).map((_, index) => (  // Render 8 skeleton items
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton width="60%" />
                <Skeleton width="40%" />
              </Grid>
            ))
          ) : (
            businessAdd ? businessAdd.map((BizAdd: AddressBizDetailed) => (
              <Grid item md={3} key={BizAdd.business.id}>
                <BusinessCard foodstore={BizAdd.business} onSelectfoodstore={handleFoodStoreselect} />
              </Grid>
            )) : (
              <Typography variant="h6">{foodstorecat} Coming Soon</Typography>
            )
          )}
        </Grid>
        <Grid item md={3}>
          <ShoppingCartFloat />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default Home;