import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import CustomerLayout from './Components/Layouts/Customer/CustomerLayout';
import AppLayout from './Components/Layouts/App/AppLayout';

const Reviews = () => {
  const location = useLocation();
  const { menuItem } = location.state || { menuItem: null };

  if (!menuItem) return <Typography variant="h6">No reviews available.</Typography>;

  return (
    <AppLayout>
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Reviews for {menuItem.name}
      </Typography>
      <List>
        {menuItem.reviews.map((review: { id: React.Key | null | undefined; author: any; date_published: string | number | Date; rating_value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; review_body: any; }) => (
          <div key={review.id}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={`${review.author} - ${new Date(review.date_published).toLocaleDateString()}`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      Rating: {review.rating_value}/5
                    </Typography>
                    <br />
                    {review.review_body}
                  </>
                }
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
    </AppLayout>
  );
};

export default Reviews;