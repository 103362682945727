import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { AddressBizDetailed } from "../../../domain/Types/AddressBizDetailed";
import { GeoSearch } from "../../../domain/Types/GeoSearch";
import { BASE_URL } from "../../../domain/Types/Config";

axiosRetry(axios, {
    retries: 3, // Number of retries
    retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
    shouldResetTimeout: true // Reset timeout on retries
  });

  export const fetchProxBusiness = async (search: GeoSearch): Promise<AddressBizDetailed[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return fetchProxBusiness(search);
      }
  
      const url = `${BASE_URL}/Geosearch`;
      const response: AxiosResponse<AddressBizDetailed[]> = await axios.post(url, search);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

