import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react"
import AppNavigation from "./AppNavigation";
import Footer from "../Customer/Footer";

const AppLayout = (props: { children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined }) => {
  return (
    <>
      <AppNavigation />
      <div className="container">      
      <div>{props.children}</div>
      </div>
      <br /><br />
      <Footer />    
    </>
  )
}

export default AppLayout