import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Popover, Slider, Box, Typography, Checkbox, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarIcon from '@mui/icons-material/Star';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { SearchMenu } from '../../../../domain/Types/SearchMenu';
import { SearchMenus } from '../../../../infrastructure/api/Menus/MenuAPI';


interface FormData {
  search: string;
  radius: number;
  dietaryOptions: string[];
  priceRange: string;
  rating: string;
  deliveryTime: string;
}

interface FoodStoreSearchFormProps {
  onSubmit: SubmitHandler<FormData>;
}

const FoodStoreSearchForm: React.FC<FoodStoreSearchFormProps> = ({ onSubmit }) => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [anchorElRadius, setAnchorElRadius] = useState<null | HTMLElement>(null);
  const [anchorElDietary, setAnchorElDietary] = useState<null | HTMLElement>(null);
  const [anchorElPrice, setAnchorElPrice] = useState<null | HTMLElement>(null);
  const [anchorElRating, setAnchorElRating] = useState<null | HTMLElement>(null);
  const [anchorElDelivery, setAnchorElDelivery] = useState<null | HTMLElement>(null);

  const [radiusValue, setRadiusValue] = useState<number>(5);  // Default radius value
  const [dietaryOptions, setDietaryOptions] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<string>('0000.00');
  const [rating, setRating] = useState<string>('+3');
  const [deliveryTime, setDeliveryTime] = useState<string>('under 1hr');

  // Geolocation state
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const navigate = useNavigate();

    // Get user's geolocation
    useEffect(() => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        }, (error) => {
          console.error('Geolocation error:', error);
        });
      }
    }, []);

  const SearchMenuOptions = async (search: SearchMenu) => {
      try {
          const data = await SearchMenus(search);
          navigate('/ExtendedSearch', { state: { results: data } });
      } catch (error) {
          console.error('Error fetching data:', error);
      } finally {
          //setLoading(false);
      }
  };

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const searchPayload: SearchMenu = {
        radius: data.radius || 5, // Default to 5 if not selected
        dietaryOptions: data.dietaryOptions || [], // Default to empty array
        priceRange: data.priceRange || '0000.00', // Default to "0000.00" if not provided
        rating: parseFloat(data.rating) || 0, // Default to empty number 0
        deliveryTime: data.deliveryTime || 'Under 1hr', // Default to "Under 30min"
        latitude: latitude || 0,  // Include latitude, default to 0 if not available
        longitude: longitude || 0,  // Include longitude, default to 0 if not available
      };      

      console.log(searchPayload);
      SearchMenuOptions(searchPayload);
      
    } catch (error) {
      console.error('Search API error:', error);
    }
  };

  const handleButtonClick = (setter: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => (event: React.MouseEvent<HTMLElement>) => {
    setter(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElRadius(null);
    setAnchorElDietary(null);
    setAnchorElPrice(null);
    setAnchorElRating(null);
    setAnchorElDelivery(null);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setRadiusValue(newValue as number);
  };

  const handleDietaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    setDietaryOptions(prev =>
      prev.includes(value) ? prev.filter(option => option !== value) : [...prev, value]
    );
  };

  const handleRadioChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setter(event.target.value);
  };

  const openRadius = Boolean(anchorElRadius);
  const openDietary = Boolean(anchorElDietary);
  const openPrice = Boolean(anchorElPrice);
  const openRating = Boolean(anchorElRating);
  const openDelivery = Boolean(anchorElDelivery);

  const MKIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props}>
      <text x="0" y="20" fontSize="18" fontFamily="Arial">MK</text>
    </SvgIcon>
  );

  const customColor = '#e88824';

  const StyledButton = styled.button`
  background-color: black;
  color: white;
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${customColor};
  }
`;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-2 mb-2">
      <div className="px-4 lg:mt-0">
        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-4">

          {/* Radius Button and Popover */}
          <Button
            variant="contained"
            onClick={handleButtonClick(setAnchorElRadius)}
            startIcon={<LocationOnIcon />}
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '12px',
              padding: '4px 8px',
              fontSize: '14px',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e88824',
              }
            }}
          >
            {radiusValue} km
          </Button>
          <Popover
            open={openRadius}
            anchorEl={anchorElRadius}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: '90%',
                maxWidth: 300
              }
            }}
          >
            <Box p={2}>
              <Typography gutterBottom>Adjust Search Radius (km)</Typography>
              <Slider
                value={radiusValue}
                onChange={handleSliderChange}
                aria-labelledby="radius-slider"
                min={0}
                max={100}
                valueLabelDisplay="auto"
              />
            </Box>
          </Popover>

          {/* Dietary Options Button and Popover */}
          <Button
            variant="contained"
            onClick={handleButtonClick(setAnchorElDietary)}
            startIcon={<FastfoodIcon />}
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '12px',
              padding: '4px 8px',
              fontSize: '14px',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e88824',
              }
            }}
          >
            Dietary
          </Button>
          <Popover
            open={openDietary}
            anchorEl={anchorElDietary}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: '90%',
                maxWidth: 300
              }
            }}
          >
            <Box p={2}>
              <Typography gutterBottom>Select Dietary Options</Typography>
              <FormControlLabel
                control={<Checkbox checked={dietaryOptions.includes('Vegetarian')} onChange={handleDietaryChange} name="Vegetarian" />}
                label="Vegetarian"
              />
              <FormControlLabel
                control={<Checkbox checked={dietaryOptions.includes('Vegan')} onChange={handleDietaryChange} name="Vegan" />}
                label="Vegan"
              />
              <FormControlLabel
                control={<Checkbox checked={dietaryOptions.includes('Gluten-Free')} onChange={handleDietaryChange} name="Gluten-Free" />}
                label="Gluten-Free"
              />
              <FormControlLabel
                control={<Checkbox checked={dietaryOptions.includes('Halal')} onChange={handleDietaryChange} name="Halal" />}
                label="Halal"
              />
            </Box>
          </Popover>

          {/* Price Range Button and Popover */}
          <Button
            variant="contained"
            onClick={handleButtonClick(setAnchorElPrice)}
            startIcon={<MKIcon />}
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '12px',
              padding: '4px 8px',
              fontSize: '14px',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e88824',
              }
            }}
          >
            {priceRange}
          </Button>
          <Popover
            open={openPrice}
            anchorEl={anchorElPrice}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: '90%',
                maxWidth: 300
              }
            }}
          >
            <Box p={2}>
              <Typography gutterBottom>Select Price Range</Typography>
              <RadioGroup value={priceRange} onChange={handleRadioChange(setPriceRange)}>
                <FormControlLabel value="00.00" control={<Radio />} label="00.00" />
                <FormControlLabel value="000.00" control={<Radio />} label="000.00" />
                <FormControlLabel value="0000.00" control={<Radio />} label="0,000.00" />
                <FormControlLabel value="00000.00" control={<Radio />} label="00,000.00" />
              </RadioGroup>
            </Box>
          </Popover>

          {/* Rating Button and Popover */}
          <Button
            variant="contained"
            onClick={handleButtonClick(setAnchorElRating)}
            startIcon={<StarIcon />}
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '12px',
              padding: '4px 8px',
              fontSize: '14px',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e88824',
              }
            }}
          >
            {rating}
          </Button>
          <Popover
            open={openRating}
            anchorEl={anchorElRating}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: '90%',
                maxWidth: 300
              }
            }}
          >
            <Box p={2}>
              <Typography gutterBottom>Select Rating</Typography>
              <RadioGroup value={rating} onChange={handleRadioChange(setRating)}>
                <FormControlLabel value="+3" control={<Radio />} label="+3" />
                <FormControlLabel value="+3.5" control={<Radio />} label="+3.5" />
                <FormControlLabel value="+4" control={<Radio />} label="+4" />
                <FormControlLabel value="+4.5" control={<Radio />} label="+4.5" />
                <FormControlLabel value="5+" control={<Radio />} label="5+" />
              </RadioGroup>
            </Box>
          </Popover>

          {/* Delivery Time Button and Popover */}
          <Button
            variant="contained"
            onClick={handleButtonClick(setAnchorElDelivery)}
            startIcon={<DeliveryDiningIcon />}
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '12px',
              padding: '4px 8px',
              fontSize: '14px',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e88824',
              }
            }}
          >
            {deliveryTime}
          </Button>
          <Popover
            open={openDelivery}
            anchorEl={anchorElDelivery}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: '90%',
                maxWidth: 300
              }
            }}
          >
            <Box p={2}>
              <Typography gutterBottom>Select Delivery Time</Typography>
              <RadioGroup value={deliveryTime} onChange={handleRadioChange(setDeliveryTime)}>
                <FormControlLabel value="under 1 hour" control={<Radio />} label="Under 1 hour" />
                <FormControlLabel value="under 30min" control={<Radio />} label="Under 30 min" />                
              </RadioGroup>
            </Box>
          </Popover>

          <Button type="submit" variant="contained" color="primary" fullWidth
          sx={{
              mb: 2,
              borderRadius: '12px',
              padding: '4px 8px',
              fontSize: '14px',
              border: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e88824',
              }
            }}>
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FoodStoreSearchForm;