import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDPvnv2F-OAMevjIyO2LkLLE1eVkMXERx8",
  authDomain: "fcmfoodhut.firebaseapp.com",
  databaseURL: "https://fcmfoodhut-default-rtdb.firebaseio.com",
  projectId: "fcmfoodhut",
  storageBucket: "fcmfoodhut.appspot.com",
  messagingSenderId: "450288019274",
  appId: "1:450288019274:web:a617afbd332b1e201003b3",
  measurementId: "G-S3KVLLSCBY"
};

const vapidKey = "BD3masS30yMWSOQm6Ey_IgREjKHHcPhb_yJefupR2a1KFVVrw45XV_g5g1KmJ7wJMiz8y5I12PAPkhrbmaPLyQ4";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFCMToken = async () => {

    return Notification.requestPermission()
    .then((permission) => {
        if (permission === "granted") {
            return getToken(messaging, { vapidKey })
        }else
        {
            throw new Error("Permission not granted");
        }
    })
    .catch ((error) => {
        console.error("Error getting FCM token", error);
        throw error;
    })
}