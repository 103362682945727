import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const FoodHutBtn = styled(Button)({
  backgroundColor: '#4caf50',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#388e3c',
  },
});

export default FoodHutBtn;
